<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <a class="navbar-brand" href="/">Loot Splitter</a>
      <div class="collapse navbar-collapse" id="navbarText">
        <span class="navbar-text ms-auto">
          <a v-if="!character" class="nav-link" :href="ssoUrl"><img src="/images/eve-sso-login-black-small.png"></a>
          <span v-if="character" class="nav-link">{{character.CharacterName}}</span>
          <a v-if="character" class="nav-link" href="#" @click="logout">Log out</a>
        </span>
      </div>
    </nav>

    <!-- Page Content -->
    <div class="container-fluid">
      <vue-progress-bar></vue-progress-bar>
      <div class="row">
        <div class="col-lg-12">
          <router-view class="view"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-slider-component/theme/antd.css'
import AuthService from './services/auth/AuthService';
import router from './router'

export default {
  name: 'app',
  data() {
    return {
      ssoUrl: process.env.VUE_APP_API_URL + 'sso',
      authService: new AuthService(),
      character: null,
    }
  },
  async mounted () {
    // this.$Progress.finish();
    // this.character = await this.authService.getCharacter();
  },
  created () {
    // this.$Progress.start()
    router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        // let meta = to.meta.progress
        // this.$Progress.parseMeta(meta)
      }
      // this.$Progress.start()
      next()
    })
    router.afterEach(() => {
      // this.$Progress.finish()
    })
  },
    methods: {
      async logout() {
        let response = await this.authService.logout();
        this.character = null;
      }
    }
}
</script>
