import _ from 'lodash';

class KillmailData {
  constructor(killmail) {
    this.originalKillmail = Object.assign({}, killmail);
    this.getKillmailDataObject(this.originalKillmail);
  }

  getKillmailDataObject(killmail) {
    this.victimName = killmail.victimName;
    this.killmailId = killmail.parsedKm.killmail_id;
    this.shipId = killmail.parsedKm.victim.ship_type_id;
    this.shipName = killmail.parsedKm.victim.shipData[0].name;
    this.items = Object.assign({}, killmail.parsedKm.victim.items);
    this.items = this.applyTotalPrices(this.items);

    this.attackers = killmail.parsedKm.attackers.slice();
    // defualt all attackers to be included in payout
    _.map(this.attackers, (attacker) => {
      attacker.included = 1;
      return attacker;
    });

    this.killmailUrl = `${process.env.VUE_APP_BASE_URL}killmail/${killmail.killmailId}`;
  }

  applyTotalPrices(items) {
    let totalCost = 0;
    items = _.map(items, (item, index) => {
      item.quantity = item.quantity_dropped || item.quantity_destroyed;
      item.cost = Math.trunc(item.quantity * item.priceData.buy.max);
      item.originalIndex = index;
      item.manuallyInclude = 0;
      item.destroyed = !item.quantity_dropped;
      if (!item.destroyed) {
        totalCost += item.cost;
      }
      return item;
    });
    this.totalDroppedCost = Math.trunc(totalCost);
    return items;
  }


  applyAltsToAttackers(alts) {
    // @todo aaa
    this.attackers = this.attackers.map((player) => {
      player.included = alts.includes(player.characterData.name) ? 2 : player.included;
      return player;
    })
  }

  refreshPayoutData(payoutData) {
    this.filterItemPriceThreshold(payoutData);
    this.calculateTotalDroppedCost(payoutData);
    this.calculatePayoutData(payoutData);
    return payoutData;
  }

  filterItemPriceThreshold(payoutData) {
    let maxPrice = 10000000;
    this.items = _.map(this.items, (item) => {
      if (item.cost > maxPrice) {
        maxPrice = item.cost;
      }
      item.included = item.cost > payoutData.payoutMinThreshold && !item.destroyed;
      return item;
    });
    return payoutData;
  }

  calculateTotalDroppedCost(payoutData) {
    payoutData.totalPay = _.sumBy(
      this.items, item => ((item.included && item.manuallyInclude !== 2) || item.manuallyInclude === 1 ? item.cost : 0),
    );
    return payoutData;
  }

  calculatePayoutData(payoutData) {
    const payPlayerCount = _.sumBy(this.attackers, player => Number(player.included === 1));

    let totalAmount = 0;
    payoutData.splitPercents = _.map(payoutData.splitPercents, (split) => {
      split.amount = Math.trunc(payoutData.totalPay * Number(split.percent / 100));
      totalAmount += split.amount;
      return split;
    });
    payoutData.totalPayAfterCut = payoutData.totalPay - totalAmount;
    payoutData.payPerPlayer = Math.trunc(payoutData.totalPayAfterCut / payPlayerCount);
    return payoutData;
  }
}

export default KillmailData;
