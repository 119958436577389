<template>
  <div v-if="killmails !== null">
    <div v-for="killmail in killmails" v-bind:key="killmail.id">
      <router-link :to="`killmail/${killmail.killmailId}`">{{ killmail.victimName }}</router-link>
    </div>
  </div>
</template>

<script>

import killmailService from "@/services/killmailService";

export default {
  name: 'KillmailTable',
  components: {},
  data () {
    return {
      pagination: {
        sort: "createdAt|desc",
        page: 1,
        perPage: 10
      },
      killmails: null
    }
  },
  async mounted() {
    const data = await killmailService.getKillmailList(this.pagination)
    this.killmails = data.data;
  },
  methods: {
    rowClicked(killmail) {
      window.location.href = `killmail/${killmail.killmailId}`;
    }
  }
}
</script>

<style>
.pagination {
  margin: 0;
  float: right;
}
.pagination a.page {
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 5px 10px;
  margin-right: 2px;
}
.pagination a.page.active {
  color: white;
  background-color: #337ab7;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 5px 10px;
  margin-right: 2px;
}
.pagination a.btn-nav {
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 5px 7px;
  margin-right: 2px;
}
.pagination a.btn-nav.disabled {
  color: lightgray;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 5px 7px;
  margin-right: 2px;
  cursor: not-allowed;
}
.pagination-info {
  float: left;
}

.killmailTable td:hover {
  cursor:pointer;
}
</style>