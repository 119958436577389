<template>
  <div v-if="killmail && payoutData">
    <div class="pull-left">
      <div class="container-fluid">
        <div class="row header">
          <div class="col-md-6" >
            <h1><img alt="ship image" class="ship-image" :src="imageBaseUrl + killmail.shipId + '.png'"> {{ killmail.victimName }} - {{ killmail.shipName }}</h1>
          </div>
          <div class="col-md-6">
            <div class="kill-links">
              <p>
                Permalink: <a v-bind:href="killmail.killmailUrl">{{killmail.killmailUrl}}</a><br />
                Zkill link: <a v-bind:href="'https://zkillboard.com/kill/' + killmail.killmailId">https://zkillboard.com/kill/{{killmail.killmailId}}</a>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <h5 class="card-header">Payout <button class="btn btn-secondary btn-sm float-right" @click="copyToClipboard" title="Paste into in game notepad">Copy to clipboard</button></h5>
              <div class="card-body">
                <payout-details
                    :payout-data="payoutData"
                    :killmail="killmail"
                    @splitUpdated="splitUpdated"
                    @addSplit="addSplit"
                    @removeSplit="removeSplit"
                    @payoutMinThresholdUpdated="payoutMinThresholdUpdated"
                ></payout-details>
              </div>
            </div>
            <br />
            <div class="card">
              <h5 class="card-header">Attackers</h5>
              <div class="card-body">
                <AttackerList
                    @selectAlt="selectAlt"
                    @attackersUpdated="attackersUpdated"
                    :attackers="killmail.attackers">
                </AttackerList>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <ItemList
                :items="killmail.items"
                :hide-destroyed="true"
                @manuallyInclude="manuallyInclude"
            ></ItemList>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import KillmailService from '../services/killmailService';
import AttackerList from "../components/killmail/AttackerList";
import ItemList from "../components/killmail/ItemList";
import PayoutDetails from "../components/killmail/PayoutDetails";
import _ from 'lodash';
import {
  addSplit,
  getKillmail,
  removeSplit,
  updateKillmail,
  updatePayoutDataSplit
} from "@/store/actions.type";
import { mapGetters } from "vuex";
import { attackersUpdated } from "@/store/mutations.type";

export default {
  name: 'KillmailPayout',
  components: {ItemList, AttackerList, PayoutDetails},
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      imageBaseUrl: process.env.VUE_APP_IMAGE_BASE_URL,
      hideDestroyed: true,
      alts: KillmailService.getAlts(),
    }
  },
  mounted() {
    this.getKillmail()
  },
  computed: {
    ...mapGetters(["killmail", "payoutData"])
  },
  methods: {
    selectAlt(altData) {
      altData.attacker.altOf = altData.altName;
    },
    copyToClipboard() {
      const text = _.reduce(this.killmail.attackers, (carry, attacker) => {
        if(attacker.included === 1) {
          carry += `<url=showinfo:1376//${attacker.characterId}>${attacker.characterData.name}</url><br />`;
        }
        return carry;
      }, `Pay: ${this.payoutData.payPerPlayer}<br />`)
      // let text = '<url=showinfo:1376//1742487620>Misic1891</url>';
      let container = this.$refs.container;
      this.$copyText(text, container)
    },
    attackersUpdated(attacker) {
      this.$store.dispatch(attackersUpdated, attacker);
      this.$store.dispatch(updateKillmail, this.killmail);
    },
    manuallyInclude(value, index) {
      this.killmail.items[index].manuallyInclude = value;
      this.$store.dispatch(updateKillmail, this.killmail);
    },
    async getKillmail() {
      await this.$store.dispatch(getKillmail, this.id);
    },

    addSplit(split) {
      this.$store.dispatch(addSplit, split);
      this.$store.dispatch(updatePayoutDataSplit, this.payoutData);
    },
    removeSplit(index) {
      this.$store.dispatch(removeSplit, index);
      this.$store.dispatch(updatePayoutDataSplit, this.payoutData);
    },

    splitUpdated() {
      this.$store.dispatch(updatePayoutDataSplit, this.payoutData);
    },
    payoutMinThresholdUpdated() {
      this.$store.dispatch(updatePayoutDataSplit, this.payoutData);
    }
  }
}
</script>

<style>
.ship-image {
  width: 50px;
  height: 50px;
}
.kill-links {
  text-align: right;
}
</style>