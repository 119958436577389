import axios from 'axios';

class AuthService {
  constructor() {
    this.character = null;
  }

  // authorize(params) {
  //
  // }

  async getCharacter() {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}character`);
    return this.character = response.data;
  }

  async logout() {
    await axios.get(`${process.env.VUE_APP_API_URL}logout`);
    this.character = null;
    return true;
  }
}

export default AuthService;
