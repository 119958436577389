<template>
  <div>
    <br />
    <PostKillmail />
    <h1>Killmails</h1>
    <a v-if="mock" href="/killmail/104588589">Mock Killmail</a>
    <KillmailTable />
  </div>
</template>

<script>
import PostKillmail from '../components/killmail/PostKillmail.vue'
import KillmailTable from "../components/killmail/KillmailTable";

export default {
  name: 'KillmailView',
  components: {
    KillmailTable,
    PostKillmail
  },
  data: function () {
    return {
      test: '',
      mock: process.env.VUE_APP_MOCK_KILLMAIL
    }
  },
}
</script>
