<template>
  <div class="autocomplete">
    <label for="search" class="text-gray-900">Type the name of a player to search</label>

    <input
      type="text"
      id="search"
      v-model="searchTerm"
      placeholder="Type here..."
      class="form-control"
    />

    <ul
      v-if="searchPlayers.length && showDropdown"
      class="dropdown-list"
    >
      <li
        v-for="player in searchPlayers"
        :key="player.name"
        @click="selectPlayer(player)"
        class="dropdown-option"
      >
        {{ player.name }}
      </li>
    </ul>

    <p v-if="selectedPlayer" class="text-lg pt-2 absolute">
      You have selected: <span class="font-semibold">{{ selectedPlayer.name }}</span>
    </p>
  </div>
</template>

<script setup>
import {ref, defineProps, defineEmits, watch} from 'vue'
import {debounce} from 'lodash';

let showDropdown = false;
let searchTerm = ref('')

const props = defineProps({
  searchFunction: {
    type: Function,
    required: true
  }
})
const emit = defineEmits(['select'])

let searchPlayers = ref([])

const searchDebounce = debounce(async() => {
  searchPlayers.value = await props.searchFunction(searchTerm)
  showDropdown = true;
  console.log(searchPlayers.value);
}, 500)

watch(searchTerm, () => {
  if(searchTerm.value !== '') {
    searchDebounce()
  }
})

let selectedPlayer = ref('')

const selectPlayer = (player) => {
  selectedPlayer.value = player
  searchTerm.value = ''
  showDropdown = false
  emit('select', player)
}

</script>

<style lang="scss">
  .dropdown-option {
    cursor: pointer;
  }
  .autocomplete {
    position: relative;
  }
  .dropdown-list {
    position: absolute;
    z-index: 1000;
    background: white;
    padding: 0;
    li {
      list-style-type: none;
      padding: 5px 10px;
    }
    li:hover {
      background: #337ab7;
    }
  }
</style>
