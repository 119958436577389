<template>
  <div>
    <div>
      <div>Total Dropped: {{killmail.totalDroppedCost.toLocaleString()}} isk</div>
      <div>Total Pay (Before payer cut): {{payoutData.totalPay.toLocaleString()}} isk</div>
      <div>Total Pay (After payer cut): {{payoutData.totalPayAfterCut.toLocaleString()}} isk</div>
      <div>Pay Per Player: {{payoutData.payPerPlayer.toLocaleString()}} isk</div>
    </div>
    <br />
    <div v-for="(split, index) in payoutData.splitPercents" v-bind:key="index">
      <vue-slider v-model="split.percent" @change="splitUpdated()" />
      <div class="row">
        <div class="col-md-1">
          <button @click="removeSplit(index)" style="display:inline-block;padding: 2px 4px" class="btn">
            <span class="fas fa-minus icon-block"></span>
          </button>
        </div>
        <div class="col-md-3">
          <input class="form-control" v-model="split.name">
        </div>
        <div class="col-md-4">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Percent</span>
            </div>
            <input type="text" class="form-control" aria-label="Default" @keydown="splitUpdated()" :id="'payerPercent' + split.name" v-model="split.percent">
          </div>
        </div>
        <div class="col-md-4">
          {{split.amount.toLocaleString()}} isk
        </div>
      </div>
    </div>
    <button @click="addSplit" style="display:inline-block;" class="btn btn-secondary">
      <span class="fas fa-plus icon-block"></span> Add split
    </button>
    <vue-slider v-model="mutablePayoutData.payoutMinThreshold" :max="maxItemPrice" :interval="1000000"/>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text">Minimum Item Price</span>
      </div>
      <input type="text" class="form-control" aria-label="Default" :id="'payoutMinThreshold'" v-model="mutablePayoutData.payoutMinThreshold">
    </div>
  </div>
</template>

<script>

import VueSlider from 'vue-slider-component'
import _ from 'lodash';

// payoutData: {
//   payerCutPercent: 20,
//     splitPercents: [
//     {
//       name: 'player',
//       percent: 20,
//     }
//   ],
//     payoutMinThreshold: 5000000,
//     totalPay: 0,
//     totalPayAfterCut: 0,
//     payoutPlayerCount: 0,
//     payPerPlayer: 0,
// }

export default {
  name: 'PayoutDetails',
  components: {VueSlider},
  props: {
    payoutData: {
      type: Object,
      required: true
    },
    killmail: {
      type: Object,
      required: true
    },
  },
  created () {
    this.splitUpdated = _.debounce(() => {
      this.$emit('splitUpdated');
    }, 100);
  },
  data: function () {
    return {
      maxItemPrice: 100000000,
      mutablePayoutData: {...this.payoutData}
    }
  },
  watch: {
    'payoutData.payoutMinThreshold': function (val) {
      this.$emit('payoutMinThresholdUpdated', val);
    }
  },
  methods: {
    removeSplit(index) {
      this.$emit('removeSplit', index);
    },
    addSplit() {
      this.$emit('addSplit', {name: '', percent: 0, amount: 0})
    },
    splitUpdated() {
      this.$emit('splitUpdated', {name: '', percent: 0, amount: 0})
    }
  }
}
</script>
