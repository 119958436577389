import { createRouter, createWebHistory } from 'vue-router'
import KillmailView from "@/views/KillmailView";
import KillmailPayout from "@/views/KillmailPayout";
import Settings from "@/views/Settings";

export default createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', name: 'Killmail List', component: KillmailView },
        { path: '/SsoFinish', redirect: { name: 'Killmail List' } },
        { path: '/killmail/:id', name: 'Killmail', component: KillmailPayout, props: true},
        { path: '/settings', name: 'Settings', component: Settings },
    ]
});