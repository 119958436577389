import axios from 'axios';
import { createApp } from 'vue'
import Vuex from 'vuex';
import VueProgressBar from "@aacassandra/vue3-progressbar";

import VueClipboard from 'vue-clipboard2';

import '@fortawesome/fontawesome-free/js/all';
import './app.scss';
import store from './store';
import App from './App.vue';
import router from './router'

const options = {
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  inverse: false,
};

axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
};

axios.defaults.withCredentials = false;

const app = createApp(App);

app.use(VueProgressBar, options);
app.use(VueClipboard);
app.use(router)
app.use(store)
app.use(Vuex);
app.mount('#app');

axios.interceptors.request.use((config) => {
  // app.$Progress.start(); // for every request start the progress
  return config;
});

axios.interceptors.response.use((response) => {
  // app.$Progress.finish(); // finish when a response is received
  return response;
});
