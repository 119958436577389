import _ from 'lodash';
import KillmailService from '../services/killmailService';
import {
  addAlt, addSplit,
  getKillmail,
  removeAlt,
  removeSplit,
  updateKillmail,
  updatePayoutData,
  updatePayoutDataSplit,
} from './actions.type';
import {
  addSplitMutation,
  attackersUpdated,
  refreshPayoutData, removeSplitMutation,
  setKillmail,
  setPayoutData,
  storeSplits,
} from './mutations.type';
import KillmailData from '../services/killmail/KillmailData';

const initialState = {
  imageBaseUrl: process.env.VUE_APP_IMAGE_BASE_URL,
  killmail: null,
  hideDestroyed: true,
  alts: KillmailService.getAlts(),
  payoutData: {
    payerCutPercent: 20,
    splitPercents: [
      {
        name: 'player',
        percent: 20,
      },
    ],
    payoutMinThreshold: 5000000,
    totalPay: 0,
    totalPayAfterCut: 0,
    payoutPlayerCount: 0,
    payPerPlayer: 0,
  },
};

export const state = { ...initialState };

export const actions = {
  async [updateKillmail](context, killmail) {
    context.commit(setKillmail, killmail);
    context.commit(refreshPayoutData);
  },
  async [updatePayoutData](context, payoutData) {
    context.commit(setPayoutData, payoutData);
    context.commit(refreshPayoutData);
  },
  async [getKillmail](context, id) {
    const service = new KillmailService();
    const killmailData = await service.getKillmailData(id);
    const killmail = new KillmailData(killmailData);

    context.commit(setKillmail, killmail);
    context.commit(refreshPayoutData);
    return killmailData;
  },

  async [updatePayoutDataSplit](context, payoutData) {
    context.commit(setPayoutData, payoutData);
    context.commit(storeSplits);
    context.commit(refreshPayoutData);
  },
  async [addSplit](context, split) {
    context.commit(addSplitMutation, split);
    context.commit(storeSplits);
    context.commit(refreshPayoutData);
  },
  async [removeSplit](context, index) {
    context.commit(removeSplitMutation, index);
    context.commit(storeSplits);
    context.commit(refreshPayoutData);
  },
  async [attackersUpdated](context, attacker) {
    const altIndex = _.findIndex(state.alts, name => attacker.characterData.name == name);
    if (altIndex === -1 && attacker.included === 2) {
      context.commit(addAlt, attacker);
    } else if (attacker.included !== 2) {
      context.commit(removeAlt, altIndex);
    }
  },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
  [setKillmail](state, killmail) {
    killmail.applyAltsToAttackers(state.alts);
    state.payoutData.splitPercents = KillmailService.getSplits();
    state.killmail = killmail;
  },
  [setPayoutData](state, payoutData) {
    state.payoutData = state.killmail.refreshPayoutData(payoutData);
  },
  [refreshPayoutData](state) {
    state.payoutData = state.killmail.refreshPayoutData(state.payoutData);
  },

  [addSplitMutation](state, split) {
    state.payoutData.splitPercents.push(split);
  },
  [removeSplitMutation](state, index) {
    if (state.payoutData.splitPercents.length > 1) {
      state.payoutData.splitPercents.splice(index, 1);
    }
  },
  [storeSplits](state) {
    KillmailService.storeSplits(_.map(state.payoutData.splitPercents, split => _.pick(split, 'name', 'percent')));
  },

  [addAlt](state, attacker) {
    state.alts.push(attacker.characterData.name);
    KillmailService.storeAlts(state.alts);
  },
  [removeAlt](state, index) {
    if (state.alts.length === 1) {
      state.alts = [];
    } else {
      state.alts = state.alts.splice(index, 1);
    }
  },
};

const getters = {
  killmail(state) {
    return state.killmail;
  },
  payoutData(state) {
    return state.payoutData;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
