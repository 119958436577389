<template>
  <div>
    <div class="input-group">
      <span class="input-group-btn">
        <button class="btn btn-primary" type="button" v-on:click="post">Post New Killmail</button>
      </span>
      <input v-model="url" type="text" class="form-control" placeholder="Link from in game" />
    </div>
  </div>
</template>

<script>
  import KillmailService from '../../services/killmailService';
  export default {
    name: 'PostKillmail',
    data: function () {
      return {
        url: '',
      }
    },
    methods: {
      post: async function() {
        let service = new KillmailService();
        let response = await service.postKillmail(this.url);
        window.location.href = `killmail/${response.data.killmailId}`;
      }
    }
  }
</script>
