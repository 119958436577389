import axios from 'axios';

class CharacterService {
    static async getCharacters(characterName) {
        const characters = await axios.get(process.env.VUE_APP_API_URL + 'characters/autocomplete/' + characterName);
        return characters.data
    }
}

export default CharacterService;
