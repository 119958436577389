<template>
  <div>
    <h1>Settings</h1>
    <button class="btn btn-primary" @click="clearStorage">Clear Storage</button>
  </div>
</template>

<script>
import killmailService from "@/services/killmailService";
export default {
  setup() {
    const clearStorage = () => {
      killmailService.clearStorage()
    };
    return {clearStorage}
  }
}
</script>