<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <h5 class="card-header">Included in payout</h5>
          <div class="card-body">
            <div v-for="(item) in orderedItems"
                 v-bind:key="item.originalIndex + item.manuallyInclude"
                 v-bind:class="{ 'bg-danger': item.destroyed,  dropped: !item.destroyed }"
                 class="mb-2 rounded-sm item"
                 v-show="(item.included && item.manuallyInclude === 0) || item.manuallyInclude === 1"
            >
              <span>({{item.quantity}}) {{item.cost.toLocaleString()}} isk - {{item.itemData.name}}</span>
              <button @click="manuallyInclude(item, 2)" style="display:inline-block;" class="float-end btn addremovebutton">
                <span v-show="item.manuallyInclude === 1" class="fas fa-angle-right icon-block"></span>
                <span class="fas fa-minus icon-block"></span>
              </button>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <h5 class="card-header">Excluded from payout</h5>
          <div class="card-body">
            <div v-for="(item) in orderedItems"
                 v-bind:key="item.originalIndex+item.manuallyInclude"
                 v-bind:class="{ 'bg-danger': item.destroyed,  dropped: !item.destroyed}"
                 class="mb-2 rounded-sm item"
                 v-show="(!item.included && item.manuallyInclude === 0) || item.manuallyInclude === 2"
            >
              <button @click="manuallyInclude(item, 1)" style="display:inline-block;" class="float-start btn addremovebutton">
                <span class="fas fa-plus icon-block"></span>
              </button>
              <div v-show="item.manuallyInclude === 2" class="fas fa-angle-left icon-block"></div>
              <span>({{item.quantity}}) {{item.cost.toLocaleString()}} isk - {{item.itemData.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash'

export default {
  name: 'ItemList',
  props: {
    items: {
      type: Array,
      required: true
    },
    hideDestroyed: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    orderedItems: function () {
      return _.orderBy(this.items, ['destroyed', 'cost'], ['asc', 'desc'])
    }
  },
  methods: {
    manuallyInclude(item, value) {
      if((value === 2 && !item.included) || (value === 1 && item.included)) {
        value = 0;
      }
      this.$emit('manuallyInclude', value, item.originalIndex);
    }
  }
}
</script>

<style scoped>

.item {
  padding: 4px 6px;
  margin-bottom: 4px !important;
}
.dropped {
  background: #34803b;
}
.addremovebutton {
  padding: 0;
}
.destroyed {
  background: #ff363b;
}
</style>
