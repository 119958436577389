<template>
  <div>
    <div v-for="(attacker, index) in attackers" v-show="attacker.characterData" v-bind:key="index">
      <div class="attacker">
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <label class="btn btn-secondary" :class="{active: attacker.included === 1}">
            <input type="radio" class="form-check-input"
                   :id="attacker.characterData.name + '-checkbox'"
                   :checked="attacker.included === 1"
                   @click="clickAttacker(attacker, 1)"
            >Include
          </label>
          <label class="btn btn-secondary" :class="{active: attacker.included === 0}">
            <input type="radio" class="form-check-input"
                   :id="attacker.characterData.name + '-checkbox'"
                   :checked="attacker.included === 0"
                   @click="clickAttacker(attacker, 0)"
            >Exclude
          </label>
          <label class="btn btn-secondary" :class="{active: attacker.included === 2}">
            <input type="radio" class="form-check-input"
                   :id="attacker.characterData.name + '-checkbox'"
                   :checked="attacker.included === 2"
                   @click="clickAttacker(attacker, 2)"
            >Alt
          </label>
        </div>
        <label class="form-check-label characterName" :for="attacker.characterData.name + '-checkbox'">{{attacker.characterData.name}}</label>
        <input type="checkbox" v-model="attacker.selectingAlt">
        <div v-if="attacker.selectingAlt">
          <AutoComplete :searchFunction="searchFunction" @select="(alt) => selectAlt(alt, attacker)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AutoComplete from "@/components/input/AutoComplete";
import CharacterService from "@/services/characterService";

export default {
  name: 'AttackerList',
  components: {AutoComplete},
  props: {
    attackers: {
      type: Array,
      required: true
    }
  },
  methods: {
    searchFunction(searchTerm) {
      return CharacterService.getCharacters(searchTerm.value)
    },
    selectAlt(alt, attacker) {
      console.log(alt)
      //@todo: fix autocompletes
      this.$emit('addAlt', {attacker: attacker, altName: alt.name});
      attacker.selectingAlt = false;
    },
    clickAttacker(attacker, value) {
      //@todo: don't mutate attacker instead commit
      attacker.included = value;
      this.$emit('attackersUpdated', attacker);
    },
  }
}
</script>

<style scoped>
  .attacker {
    width:100%;
  }

  .characterName {
    margin-left: 5px;
  }

  .attacker .btn-group-toggle .btn {
    padding: 2px 5px;
    margin-bottom: 5px;
    cursor:pointer;
  }

  .attacker .btn-group-toggle label.active {
    color: #dc3545;
  }
</style>
