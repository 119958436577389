import axios from 'axios';
import mockKillmail from '../../test/data/ParsedKillmail.json';
import localStorage from './localStorage';
import { each } from 'lodash';

const storageKeys = {
  alts: 'LootSplitter.alts',
  splits: 'LootSplitter.splits'
}

class KillmailService {

  static clearStorage() {
    each(storageKeys, (key) => {
      localStorage.removeItem(key);
    })
  }
  static async getKillmailList(params) {
    const requestParams = {
      sort: params.sort,
      page: params.page,
      per_page: params.perPage
    }
    if (process.env.VUE_APP_MOCK_KILLMAIL === 'true') {
      return {
        data: [mockKillmail],
        latePage: 1,
        perPage: 10,
        total: 1,
      };
    }
    const request = `?${new URLSearchParams(requestParams).toString()}`;
    const url = `${process.env.VUE_APP_API_URL}killmail${request}`;
    const result = await axios.get(url);
    return result.data;
  }

  async getKillmailData(id) {
    if (process.env.VUE_APP_MOCK_KILLMAIL === 'true') {
      return mockKillmail;
    }
    const response = await axios.get(`${process.env.VUE_APP_API_URL}killmail/${id}`);
    return response.data;
  }

  async postKillmail(url) {
    return await axios.post(`${process.env.VUE_APP_API_URL}killmail`, { url });
  }

  static getKillmails(page = 1, perPage = 20, order = { sortBy: 'id', direction: 'desc' }) {
    const baseUrl = `${process.env.VUE_APP_API_URL}/killmail?page=${page}&perPage=${perPage}&sortBy=${order.sortBy}&direction=${order.direction}`;
    return axios.get(baseUrl);
  }

  static storeAlts(alts) {
    localStorage.setItem(storageKeys.alts, JSON.stringify(alts));
  }

  static getAlts() {
    const alts = localStorage.getItem(storageKeys.alts);
    return alts ? JSON.parse(alts) : [];
  }

  static storeSplits(splits) {
    localStorage.setItem(storageKeys.splits, JSON.stringify(splits));
  }

  static getSplits() {
    const splits = localStorage.getItem(storageKeys.splits);
    return splits ? JSON.parse(splits) : [
      {
        name: 'player',
        percent: 20,
      },
    ];
  }
}

export default KillmailService;
